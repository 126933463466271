import * as React from "react";
import Layout from "../components/Layout";

import until_now_1 from "../img/sponsor/until_now_1.png";
import until_now_2 from "../img/sponsor/until_now_2.png";
import until_now_3 from "../img/sponsor/until_now_3.png";
import until_now_4 from "../img/sponsor/whatislax.png";
import until_now_5 from "../img/sponsor/until_now_5.png";
import until_now_6 from "../img/sponsor/until_now_6.png";
import until_now_7 from "../img/sponsor/until_now_7.png";
import yume from "../img/sponsor/yume.png";
import whatislax from "../img/sponsor/whatislax.png";
import ouen_1 from "../img/sponsor/1.png";
import ouen_2 from "../img/sponsor/2.png";
import ouen_3 from "../img/sponsor/3.png";
import ouen_4 from "../img/sponsor/4.png";
import ouen_5 from "../img/sponsor/5.png";
import orei from "../img/sponsor/orei.png";




const NotFoundPage = () => (
  <Layout>
    <div className="content">
      <section className="section section--gradient" style={{backgroundColor: "#E4F2FC"}}>
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h2 className="has-text-weight-semibold is-size-2">
                2023年シーズンスポンサーさま募集
              </h2>
              <div className="mb-6" style={{ marginBottom: "6rem" }}>
                <img
                  className="margin-btm-0"
                  src={yume}
                  alt=""
                  width="100%"
                  height="auto"
                />
              </div>
              <div className="columns">
                <div className="column is-7">
                  <div class="box content is-medium">
                    <h3 className="has-text-weight-semibold is-size-3">
                      ラクロスとは？
                    </h3>
                    <figure class="image is-16by9">
                      <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/UIovig9YCqg?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </figure>

                    <figure class="image is-16by9">
                      <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/rrdmk5Q6opU" frameborder="0" allowfullscreen></iframe>
                    </figure>

                    <p>　ラクロスは史上最速の格闘球技と呼ばれており、クロスと呼ばれるスティックを持って2チームがゴールに向かって点を取り合う競技。<br />

                    　北米発祥のスポーツで100年ほど前から行われており、世界人口は約80万人のうち約60万人がアメリカ人、約10万人がカナダ人、日本では35年ほど前に初めてチームが結成され、現在は約3万人ほどです。<br />

                    　日本ではほとんどの人が大学から初めており、プロはありませんが社会人リーグも盛んです。4年に1度W杯が開催されており、日本男子は世界6位です。<br />

                    　男子と女子でルールが異なり、外で行うフィールドラクロスと室内で行うBOXラクロス（日本にはない）という大きく2つの種類があります。<br />
                    </p>
                    <img
                      className="margin-btm-0"
                      src={whatislax}
                      alt=""
                      width="100%"
                      height="auto"
                    />
                    <p> ※W杯はフィールドラクロス、2028年オリンピックは上記どちらでもなく6人制のオリンピック専用の形式です。<br />

                    　現在はBOXラクロスを中心にプレーしており、トッププロのNLLを目指している状況です。アメリカのラクロス人口はどんどん増えており、新しいプロリーグも最近できています。<br />
                    </p>
                    <h3 className="has-text-weight-semibold is-size-3">
                      これまでと支援をお願いする理由
                    </h3>
                    <img
                      className="margin-btm-0"
                      src={until_now_1}
                      alt=""
                      width="100%"
                      height="auto"
                    />
                    <p>
                    　福岡の中村学園大学に入学し、ラクロスに出会い、ラクロス部を創部するところから全てが始まりました。男子学生は約700人しかいない中、熱量を伝えながら勧誘に力を入れた結果、4年次には総勢45人のチームになりました。<br />

                    　個人としては、全国のチームへ武者修行にいき、自身のスキルアップを図るも22歳以下の日本代表の選考会は一次選考で落選。おかげで日本代表になるという目標ができ、海外への挑戦がスタートしました。<br />

                    　卒業後2年間はトビタテ留学Japanの奨学金をもらいながら留学し、帰国後再び海外挑戦を続けたいと思いスポンサー集めを始めました。<br />

                    　昨年からは、スポンサーしてくださる企業様や個人の方からの支援のおかげでトッププロ選手との練習や、レベルの高い環境でプレーができるようになりました。<br />

                    　海外で挑戦し続けられているからこそ、現地での繋がりや機会がどんどん増え、おかげでトッププロリーグのトライアウトに呼んでもらえたり、新設プロリーグに参加させてもらえたりと貴重な経験ができています。<br />
                    </p>
                    <img
                      className="margin-btm-0"
                      src={until_now_2}
                      alt=""
                      width="100%"
                      height="auto"
                    />
                    <img
                      className="margin-btm-0"
                      src={until_now_3}
                      alt=""
                      width="100%"
                      height="auto"
                    />
                    <p>
                    　しかし、今春の日本代表選考会では、自分と同世代のポジションの選手を圧倒できず代表入りとはなりませんでした。フィールドラクロスではFOというポジションをしており（海外ではBOXラクロスに挑戦しておりATというポジション）、FOは試合開始時や得点後の再スタートの際に1対1で行われ、バスケでいうとジャンプボールのような位置付けです。<br />

                    　これまでほぼ勝てなかった選手も数名いましたが、前回の選考会では勝率で見ると日本3位というレベルまで上がってこれました。<br />

                    　ラクロスのW杯は来年の2023年6月に開催されるため、来年こそは代表入りできるようこの半年でさらにレベルアップして、1位の座を取りに行かなければなりません。<br />

                    　スポンサー様などのご支援のおかげで海外挑戦はできているものの、予想外の出費が多く（アメリカで購入した車が古すぎてカナダで登録できず再度買うことになったり、自動車保険が年間40万以上もしたり、、、）貯金はなくなり、円安の影響もあり海外に居続けることもギリギリの状態になっています。<br />

                    　ですが、来年のW杯で日本代表入りを果たすためには、世界トップ選手と対戦しレベルを上げ続けることが唯一の道です。<br />

                    　また、来年のW杯で日本代表に入れなければ、その後のオリンピックへの道もさらに難しくなると感じており、より一層精進しているところです。仮に日本代表に入れても、世界で通用する選手でなければ意味がありません。<br />

                    　時間が限られており、これまで以上にプレーに集中したいため、今回このような支援をお願いするという経緯となりました。<br />
                    </p>
                    <h3 className="has-text-weight-semibold is-size-3">
                      ご支援のお礼
                    </h3>
                    <img
                      className="margin-btm-0"
                      src={orei}
                      alt=""
                      width="100%"
                      height="auto"
                    />

                    <p>
                    　それぞれ選んでもらったプランのSNS投稿時に、お名前記載やタグ付けをして投稿します。そのほかも支援額に応じてお礼をさせていただきます。<br />

                    　FBグループ招待・・・非公開FBグループでは、現地の様子をリアルタイムでお届けできるよう妻が発信しています。<br />

                    　HPでの記事紹介・・・今後HPのブログをどんどん更新していく予定です。その中で応援してくださっている方の紹介記事も作成します（皆様の熱い思いを妻がインタビューして記事にする予定です）<br />

                    　シーズン報告動画・・・シーズン終了ごとに、報告動画を作成しお届けします。<br />

                    　来年のW杯まではプレーに専念したいため、あまり大したお礼はできませんが、今後期待しておいてください。<br /><br />


                      <h4 className="has-text-weight-semibold is-size-4">
                        2022年ー2023年の予定
                      </h4>
                      <b>2022年</b><br />
                      12月 PBLA or ALL トレーニングキャンプ<br />
                      現在PBLAの1チームとサインをしたが、12月に毎週行われるトレーニングキャンプの結果で正式にメンバー入りできるかどうか確定する<br />
                      同時にビザの手続きも進めているが、間に合うのか、いつもらえるのか全くわからないためひとまず拠点を決めずにアメリカに滞在予定。<br />
                      <b>2023年</b><br />
                      1-4月 シーズン & FOトレーニング<br />
                      春頃　帰国<br />
                      7月　世界大会<br />
                      10月　NLL トライアウト<br />

                    </p>
                  </div>
                </div>
                <div className="column is-5">
                  <div class="box">
                    <h3 className="has-text-weight-semibold is-size-3">
                      支援プラン
                    </h3>
                    <p>5つのプランがありますので応援したいプランを選んでください。<br />
                      特に希望がなければ⑤のプランからご支援お願いします。<br />
                    </p>
                    <div class="box">
                      <h4 className="has-text-weight-semibold is-size-4">
                        ①フェイスオフ（FO）応援プラン
                      </h4>
                      <img
                        className="margin-btm-0"
                        src={ouen_1}
                        alt=""
                        width="100%"
                        height="auto"
                      />
                      <p>
                      　FOは試合のはじめに行われるバスケでいうジャンプボール。試合開始後と得点後にFOから始まるため、どちらが主導権を握れるかがかかっている重要なポジション。<br />

                      　FOのポジションで2023年W杯で日本代表になり、トップ選手に勝つためにこの半年はFOに集中してトレーニングをする。<br />

                      　北米ではスポーツでもお金がしっかり回っているため、クリニック費は安くはないが、最低でも月に5回は取り組む予定。（今冬の滞在場所が未定なためグランド代は最大費用の想定。）<br />
                      </p>
                      <p><a href="https://square.link/u/hoMPIlo9" target="_blank" rel="noopener noreferrer"><button class="button is-link">$30 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/V4OILMD77NVY4TI3O247RUWT" target="_blank" rel="noopener noreferrer"><button class="button is-link">$50 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/5IDO5Z3432DKGZWDGZLZ6HOJ" target="_blank" rel="noopener noreferrer"><button class="button is-link">$100 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/JMPOPQSKNNPC2UN6KUTOKK75" target="_blank" rel="noopener noreferrer"><button class="button is-link">$300 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/UBXQTKFJ6DHEYUCTV3BWQVY2" target="_blank" rel="noopener noreferrer"><button class="button is-link">$500 応援</button></a></p>
                    </div>
                    <div class="box">

                      <h4 className="has-text-weight-semibold is-size-4">
                        ②シューティング応援プラン
                      </h4>
                      <img
                        className="margin-btm-0"
                        src={ouen_2}
                        alt=""
                        width="100%"
                        height="auto"
                      />
                      <p>
                      　2022年12月ー2023年5月に開催されるPBLAという新設のBOXラクロスリーグにAT（点とるポジション）として参加予定。北米トッププロリーグ（NLL）でプレーするためにも、試合で結果を残すことが重要。<br />

                      　W杯とは違う室内のラクロスだが、2028年のオリンピック形式（6人制ラクロス）と似ているため、プロへの道はもちろん、細かいスキルを磨く練習にも繋がる。<br />

                      　通常はゴールキーパーなしで自主練習をしているが、BOXラクロスはゴールが小さいためより実践的に練習できるのが理想。<br />

                      </p>
                      <p><a href="https://square.link/u/hoMPIlo9" target="_blank" rel="noopener noreferrer"><button class="button is-link">$30 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/V4OILMD77NVY4TI3O247RUWT" target="_blank" rel="noopener noreferrer"><button class="button is-link">$50 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/5IDO5Z3432DKGZWDGZLZ6HOJ" target="_blank" rel="noopener noreferrer"><button class="button is-link">$100 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/JMPOPQSKNNPC2UN6KUTOKK75" target="_blank" rel="noopener noreferrer"><button class="button is-link">$300 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/UBXQTKFJ6DHEYUCTV3BWQVY2" target="_blank" rel="noopener noreferrer"><button class="button is-link">$500 応援</button></a></p>
                    </div>
                    <div class="box">
                      <h4 className="has-text-weight-semibold is-size-4">
                        ③筋トレ応援プラン
                      </h4>
                      <img
                        className="margin-btm-0"
                        src={ouen_3}
                        alt=""
                        width="100%"
                        height="auto"
                      />
                      <p>
                      　もともと全然太らない体質で、大学時代は「身長174cm・体重50kg」だったが、海外では圧倒的に力負けしてしまうので卒業後本格的にフィジカル強化開始。<br />

                      　現在は77kgまで強化できたが、80kgでキレる身体をキープできるようトレーニング中。（試合では他の選手と比べまだまだ小さく、弱そうに見える現状）<br />

                      </p>
                      <p><a href="https://square.link/u/hoMPIlo9" target="_blank" rel="noopener noreferrer"><button class="button is-link">$30 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/V4OILMD77NVY4TI3O247RUWT" target="_blank" rel="noopener noreferrer"><button class="button is-link">$50 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/5IDO5Z3432DKGZWDGZLZ6HOJ" target="_blank" rel="noopener noreferrer"><button class="button is-link">$100 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/JMPOPQSKNNPC2UN6KUTOKK75" target="_blank" rel="noopener noreferrer"><button class="button is-link">$300 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/UBXQTKFJ6DHEYUCTV3BWQVY2" target="_blank" rel="noopener noreferrer"><button class="button is-link">$500 応援</button></a></p>
                    </div>
                    <div class="box">
                      <h4 className="has-text-weight-semibold is-size-4">
                        ④アジリティ応援プラン
                      </h4>
                      <img
                        className="margin-btm-0"
                        src={ouen_4}
                        alt=""
                        width="100%"
                        height="auto"
                      />
                      <p>
                      　ここ数年はFOに特化していたため、ATとして活躍するには基礎体力の向上が必須。瞬発力や持久力を武器として戦えるよう今年から力を入れ始めた。<br />

                      　現在はオンラインでトレーナーにメニューを組んでもらいながらトレーニング中。<br />

                      </p>
                      <p><a href="https://square.link/u/hoMPIlo9" target="_blank" rel="noopener noreferrer"><button class="button is-link">$30 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/V4OILMD77NVY4TI3O247RUWT" target="_blank" rel="noopener noreferrer"><button class="button is-link">$50 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/5IDO5Z3432DKGZWDGZLZ6HOJ" target="_blank" rel="noopener noreferrer"><button class="button is-link">$100 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/JMPOPQSKNNPC2UN6KUTOKK75" target="_blank" rel="noopener noreferrer"><button class="button is-link">$300 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/UBXQTKFJ6DHEYUCTV3BWQVY2" target="_blank" rel="noopener noreferrer"><button class="button is-link">$500 応援</button></a></p>
                    </div>
                    <div class="box">
                      <h4 className="has-text-weight-semibold is-size-4">
                        ⑤海外生活応援プラン
                      </h4>
                      <img
                        className="margin-btm-0"
                        src={ouen_5}
                        alt=""
                        width="100%"
                        height="auto"
                      />
                      <p>
                      　ご支援いただいているおかげで海外での挑戦が始まり、改めて海外でプレーすることの重要性を実感した。技術向上はもちろん、現地にいることで声をかけてもらえることも多いため、時間が限られている中で1日でも長く滞在できるよう試行錯誤中。<br />

                      　また、現地で得た情報は日本の今後のラクロス界の発展のために発信、そして次世代の若者が海外へ自分の可能性を信じて挑戦できるきっかけになれるよう取り組んでいきます。<br />

                      　現地の様子が伝わるように、ラスロスだけでなく海外生活の様子もYoutubeで発信します。いただいたご支援は家賃や渡航費、車代に使わせていただきます。<br />

                      </p>
                      <p><a href="https://square.link/u/hoMPIlo9" target="_blank" rel="noopener noreferrer"><button class="button is-link">$30 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/V4OILMD77NVY4TI3O247RUWT" target="_blank" rel="noopener noreferrer"><button class="button is-link">$50 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/5IDO5Z3432DKGZWDGZLZ6HOJ" target="_blank" rel="noopener noreferrer"><button class="button is-link">$100 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/JMPOPQSKNNPC2UN6KUTOKK75" target="_blank" rel="noopener noreferrer"><button class="button is-link">$300 応援</button></a></p>
                      <p><a href="https://checkout.square.site/merchant/ML3J7HBTQGQBJ/checkout/UBXQTKFJ6DHEYUCTV3BWQVY2" target="_blank" rel="noopener noreferrer"><button class="button is-link">$500 応援</button></a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
